

export default function getMetatags(field, ogImage = '/OG.png') {

  //console.log('ogImage=', ogImage)

  const metaTags = field.filter(f => f.tag === 'meta').map(f => ({ name: f.attributes.name, content: f.attributes.content }))

  const meta = { meta: [] }
  metaTags.forEach(e => {
    meta[e.name] = e.content
  });

  if (ogImage) {
    //console.log('in')
    //meta.meta = []
    meta.meta.push({
      property: `og:image`,
      content: ogImage,
    })
  }

  return meta;

}
