import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout-minhelt/layout"
import Seo from "../components/Seo"
import getMetatags from "../util/getMetatags"
import { HeroMinhelet } from "../components/hero"
import Image from "../components/Image"

const TeamsPage = ({ data, ...props }) => {

  const seo = getMetatags(data.node.metatag/* , nodeOverview.relationships.OGImage?.localFile?.publicURL */)
  const { subtitle } = data.node
  const { hero, members } = data.node.relationships

  return (
    <Layout>
      <Seo {...seo} />
      <HeroMinhelet
        className="mt-[90px] lg:mt-[126px]  h-[147px] lg:h-[350px] "
        {...hero}
        url={hero?.relationships?.image?.localFile?.publicURL}
      />


      <section className="py-28">
        <div className="container space-y-28">
          <h2 className="text-center text-blue text-[34px] font-bold">{subtitle}</h2>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-24">
            {members.map((member, index) => (
              <div key={index} className="w-[337px] mx-auto text-lg font-bold" >
                <Image image={member.relationships.image} /*alt={member.image.alt}*/ />

                <div className="text-blue mt-6">{member.title}</div>
                <div>{member.label}</div>

              </div>
            ))}
          </div>
        </div>
      </section>

    </Layout >
  )
}

export default TeamsPage

/* export const Head = () => <title>Basic1 Page</title> */

export const query = graphql`
  query teamQuery($id: String!) {
    node: nodeTeam(id: {eq: $id }) {
      id
      title
      subtitle: field_title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      relationships {
        hero: field_hero {
          ...Hero
        }
        members: field_paragraph_repeater{
          ...TeamMember
        }
      }
    }
  }
`
